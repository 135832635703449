import React, { useContext, useRef } from 'react';
import { useQueryClient } from 'react-query';
import useWebSocket from 'react-use-websocket';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { useSubscriber } from 'src/context/sub-auth-context';
import { getUnixEpoch } from 'src/utils/utils';
import { useGetRepMe } from 'src/hooks/repHooks';
import { useSnackbar } from 'notistack';
import { updateEventDataAC } from 'src/context/socket-context';
import { SocketContext } from 'src/context/socket-context';

export type EventSocketParams = {
  user_id?: number;
  event_id?: number;
};

export const usePcsUserEventSocket = (params: EventSocketParams) => {
  const didUnmount = useRef(false);
  const queryClient = useQueryClient();
  const { token } = usePcsAuthRep();
  const [_, socketDispatch] = useContext(SocketContext);
  // Websocket
  let socketUrl: string | null;
  if (params.user_id) {
    socketUrl = `${process.env.REACT_APP_PCS_SOCKET_ROUTER_WS_URL}/socket/user-event-ws`;
  } else if (params.event_id) {
    socketUrl = `${process.env.REACT_APP_PCS_SOCKET_ROUTER_WS_URL}/socket/event-ws`;
  } else {
    socketUrl = null;
  }

  const { lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    queryParams: {
      access_token: encodeURIComponent(token),
      ...params,
    },
    onOpen: () => console.log('pcs user_event socket connected'),
    onClose: () => console.log('pcs user_event socket closed'),
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 50,
    reconnectInterval: 10000,
    share: true,
  });

  React.useEffect(() => {
    const data = lastJsonMessage;
    if (data?.entity) {
      const queryKey = [...data.entity, data.id].filter(Boolean);
      queryClient.invalidateQueries(queryKey);
    }
    if (data?.eventId) {
      socketDispatch(updateEventDataAC(data));
    }
  }, [queryClient, lastJsonMessage]);

  React.useEffect(() => {
    return () => {
      didUnmount.current = true;
    }
  }, []);

  return { readyState };
};

export const usePcsTakeEventSocket = (accept) => {
  const didUnmount = useRef(false);
  const queryClient = useQueryClient();
  const { token, logout } = usePcsAuthRep();
  const rep = useGetRepMe();
  const socketUrl = `${process.env.REACT_APP_PCS_SOCKET_ROUTER_WS_URL}/socket/rep-take-event-ws`;
  const { enqueueSnackbar } = useSnackbar();
  const { lastJsonMessage, readyState, sendMessage } = useWebSocket(
    socketUrl,
    {
      queryParams: {
        access_token: encodeURIComponent(token),
        reps_email: encodeURIComponent(rep?.data?.email),
      },
      onOpen: () => {
        console.log('pcs take_event socket connected');
      },
      onClose: () => {
        console.log('pcs take_event socket closed');
      },
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 50,
      reconnectInterval: 10000,
    },
    rep?.data?.email ? true : false
  );

  React.useEffect(() => {
    const data = lastJsonMessage;
    if (data?.entity == 'notification')
      enqueueSnackbar(`User ${data?.user_id} canceled ${data.event_id}`, {
        variant: 'info',
      });
    console.log('SOCKET: ', lastJsonMessage);
    if (data?.entity) {
      const queryKey = [...data.entity, data.id].filter(Boolean);
      setTimeout(() => queryClient.invalidateQueries(queryKey));
    }
    if (data?.entity == 'force_logout') {
      logout();
    }
  }, [queryClient, lastJsonMessage]);

  React.useEffect(() => {
    // Make sure we always send down current accept value on open
    if (readyState === 1) {
      // Send down the latest accept value
      sendMessage(JSON.stringify({ accept }));
    }
  }, [accept, readyState]);

  React.useEffect(() => {
    return () => {
      didUnmount.current = true;
    }
  }, []);

  return { readyState };
};

// SIMPLISAFE SOCKET
export const useSsUserSocket = (user_id: number) => {
  const didUnmount = useRef(false);
  const queryClient = useQueryClient();
  const { access_token } = useSubscriber(user_id);

  // Websocket
  const socketUrl = `${process.env.REACT_APP_SOCKETLINK_WS_URL}`;

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onOpen: () => console.log('ss socket connected'),
      onClose: () => console.log('ss socket closed'),
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 50,
      reconnectInterval: 10000,
      share: true,
    }
  );

  React.useEffect(() => {
    if (!user_id || !access_token) return;
    const data = lastJsonMessage;
    if (data?.type === 'com.simplisafe.service.hello') {
      const identity = {
        datacontenttype: 'application/json',
        type: 'com.simplisafe.connection.identify',
        time: new Date().toISOString(),
        id: 'ts:' + getUnixEpoch(),
        specversion: '1.0',
        source: 'PCS-User',
        data: {
          auth: {
            schema: 'bearer',
            token: access_token,
          },
        },
      };
      identity['data']['join'] = ['uid:' + user_id];
      sendJsonMessage(identity);
    }
    if (
      data?.type === 'com.simplisafe.event.standard' &&
      ['activity', 'activityQuiet'].includes(data?.data?.eventType)
    ) {
      // queryClient.setQueriesData(
      //   ['subscriptions', user_id],
      //   (previous: SsSubscriptionsObject) => {
      //     console.log(previous);
      //     previous.subscriptions = previous?.subscriptions?.map(
      //       (subscription) => {
      //         if (subscription.sid === data.data.sid) {
      //           if (data.data.eventCid in [3401, 9401, 3407, 9407])
      //             subscription.location.system.alarmState = 'AWAY';
      //           if (data.data.eventCid in [3441, 9441])
      //             subscription.location.system.alarmState = 'HOME';
      //           if (data.data.eventCid in [1400, 1407])
      //             subscription.location.system.alarmState = 'OFF';
      //         }
      //         return subscription;
      //       }
      //     );
      //     return previous;
      //   }
      // );
      queryClient.invalidateQueries([
        'subscriptions',
        user_id,
        data?.data?.sid,
      ]);
      queryClient.invalidateQueries(['alarm_state', data?.data?.sid]);
      queryClient.invalidateQueries(['ss_events', data?.data?.sid]);
    }
  }, [queryClient, lastJsonMessage, sendJsonMessage, user_id, access_token]);

  React.useEffect(() => {
    return () => {
      didUnmount.current = true;
    }
  }, []);

  return { readyState };
};
