import { useEffect, useRef, useState } from 'react';
import { playSiren, playStart, playStop } from './AudioUtils';
import {useGetUserSettings} from 'src/hooks/settingsHooks';

export enum AudioSocketState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export type AudioStreamProps = {
  audioStream: MediaStream;
  stop: () => Promise<void>;
  audioContext: AudioContext;
  mediaStreamDestination: AudioNode;
};

export async function getAudioStream(
  gain = 3,
  userAudio = true
): Promise<AudioStreamProps> {
  const context = new AudioContext();
  const mediaStreamDestination = context.createMediaStreamDestination();
  const gainNode = context.createGain();
  // FILTERS
  const low = context.createBiquadFilter();
  low.type = 'lowshelf';
  low.frequency.value = 800.0;
  low.gain.value = gain; // dB

  const mid = context.createBiquadFilter();
  mid.type = 'peaking';
  mid.frequency.value = 960.0;
  mid.Q.value = 7;
  mid.gain.value = 0.0;
  low.connect(mid);

  const high = context.createBiquadFilter();
  high.type = 'highshelf';
  high.frequency.value = 1100.0;
  high.gain.value = gain;
  mid.connect(high);

  const filter = context.createBiquadFilter();
  filter.frequency.value = 10000.0;
  filter.type = 'lowpass';
  high.connect(filter);

  filter.connect(gainNode);
  gainNode.connect(mediaStreamDestination);

  /**
   * Change the gain levels on the input selector.
   */
  gainNode.gain.value = gain;

  let userAudioStreamSource;
  let userAudioStream;
  if (userAudio) {
    userAudioStream = await window.navigator.mediaDevices.getUserMedia({
      audio: {
        sampleSize: 16,
        channelCount: 1,
      },
      video: false,
    });
    userAudioStreamSource = context.createMediaStreamSource(userAudioStream);
    userAudioStreamSource.connect(low);
  }

  return {
    audioContext: context,
    mediaStreamDestination: low,
    audioStream: mediaStreamDestination.stream,
    stop: async () => {
      if (userAudioStream) {
        userAudioStream.getTracks().forEach((track) => track.stop());
      }
      console.log('audio context closed');
    },
  };
}

export type AudioRecorderProps = {
  buffer: Blob[];
  stop: () => Promise<void>;
};

export function getAudioRecorder(
  audioStream: MediaStream,
  frequency = 25
): AudioRecorderProps {
  const mediaRecorder = new MediaRecorder(audioStream, {
    mimeType: 'audio/webm;codecs=opus',
  });
  const buffer: Blob[] = [];
  mediaRecorder.ondataavailable = (event) => {
    buffer.push(event.data);
  };
  frequency ? mediaRecorder.start(frequency) : mediaRecorder.start(frequency);
  return {
    buffer,
    stop: () =>
      new Promise<void>((res) => {
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.onstop = () => res();
          mediaRecorder.stop();
        } else {
          res();
        }
      }),
  };
}

type AudioSocket = {
  stop: () => Promise<void>;
  ws: WebSocket;
  audioContext: AudioContext;
  mediaStreamDestination: AudioNode;
};

async function getAudioSocket(
  url: string,
  gain: number,
  onWsCommClosed?: () => Promise<void> | void
): Promise<AudioSocket> {
  const {
    audioStream,
    stop: stopUserStream,
    audioContext,
    mediaStreamDestination,
  } = await getAudioStream(gain, true);
  const { buffer, stop: stopRecorder } = getAudioRecorder(audioStream);
  await playStart(mediaStreamDestination, audioContext);
  const ws = new WebSocket(url);
  let running = true;
  let interval;
  ws.onopen = () => {
    interval = setInterval(() => {
      if (running) {
        const blobs = buffer.splice(0);
        blobs.forEach((blob) => ws.send(blob));
      }
    }, 5);
  };
  ws.onclose = async () => {
    console.log('ws closing, not triggered by stop');
    running = false;
    clearInterval(interval);
    await Promise.all([stopRecorder(), stopUserStream()]);

    if (typeof onWsCommClosed === 'function') {
      console.log(
        'Calling the callback for server started disconnection or network issues disconnection'
      );
      await onWsCommClosed();
    }
  };
  return {
    audioContext,
    mediaStreamDestination,
    ws: ws,
    stop: async (): Promise<void> => {
      await playStop(mediaStreamDestination, audioContext);

      await new Promise<void>((resolve) => {
        ws.onclose = () => undefined;
        running = false;
        clearInterval(interval);

        const closeWebSocket = () => {
          if (ws.readyState === WebSocket.CLOSING) {
            console.log('ws closing, triggered by stop, was closing');
            ws.onclose = () => {
              resolve();
            };
            return;
          }

          if (ws.readyState !== WebSocket.CLOSED) {
            console.log(
              'ws closing, triggered by stop, was not closed or closing'
            );
            ws.onclose = () => {
              resolve();
            };
            ws.close();
            return;
          }

          console.log('ws closing, triggered by stop, was closed');
          resolve();
        };

        return Promise.all([stopRecorder(), stopUserStream()]).finally(() => {
          closeWebSocket();
        });
      });
    },
  };
}

export async function playSirenSocket(url: string, gain: number): Promise<void> {
  const {
    audioStream,
    stop: stopUserStream,
    audioContext,
    mediaStreamDestination,
  } = await getAudioStream(gain, false);
  const { buffer, stop: stopRecorder } = getAudioRecorder(audioStream);
  await new Promise<void>((res) => {
    const ws = new WebSocket(url);
    let running = true;
    let interval;
    ws.onopen = () => {
      interval = setInterval(() => {
        if (running) {
          const blobs = buffer.splice(0);
          blobs.forEach((blob) => ws.send(blob));
        }
      }, 5);
    };
    ws.onclose = () => {
      res();
    };
    playSiren(mediaStreamDestination, audioContext).then(() => {
      running = false;
      clearInterval(interval);
      ws.close();
      return Promise.all([stopRecorder(), stopUserStream()]);
    });
  });
}

export function useStreamRecorder(
  url: string,
  onClosedConnection = () => undefined
): {
  stop: () => void;
  playSiren: () => Promise<void>;
  readyState: AudioSocketState;
  error: string;
} {
  const [error, setError] = useState('');
  const [readyState, setReadyState] = useState(-1);
  const { data } = useGetUserSettings();
  const { mic_volume: volume } = data ?? { mic_volume: 3 };
  const audioSocketConnecting = useRef<boolean>(false);
  const audioSocket = useRef<AudioSocket | null>(null);

  useEffect(() => {
    const readyStateCheckerInterval = setInterval(() => {
      if (!audioSocketConnecting.current) {
        setReadyState(
          audioSocket.current && audioSocket.current.ws
            ? audioSocket.current.ws.readyState
            : AudioSocketState.UNINSTANTIATED
        );
        return;
      }
      setReadyState(AudioSocketState.CONNECTING);
    }, 300);

    return () => {
      if (audioSocket.current) {
        audioSocket.current.stop();
        audioSocket.current = null;
      }
      clearInterval(readyStateCheckerInterval);
    };
  }, [audioSocket]);

  async function connectToAudioSocket(value) {
    if (!audioSocketConnecting.current) {
      audioSocketConnecting.current = true;
      try {
        audioSocket.current = await getAudioSocket(value, volume, onClosedConnection);
      } catch (e) {
        setError(String(e));
      }
      audioSocketConnecting.current = false;
    }
  }

  useEffect(() => {
    async function connect() {
      if (!url) {
        if (audioSocket.current) {
          onClosedConnection();
          const toClose = audioSocket.current;
          audioSocket.current = null;
          await toClose.stop();
        }
        return;
      }

      if (audioSocket.current) {
        await audioSocket.current.stop();
        audioSocket.current = null;
      }
      await connectToAudioSocket(url);
    }

    connect();
  }, [url]);

  return {
    error,
    playSiren: async () => {
      if (audioSocket.current) {
        await playSiren(
          audioSocket.current.mediaStreamDestination,
          audioSocket.current.audioContext
        );
      }
    },
    readyState,
    stop: audioSocket.current?.stop || (() => undefined),
  };
}
